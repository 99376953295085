// sw-registration.ts
export function registerSW(): void {
    // @ts-ignore
    if ("serviceWorker" in navigator) {
        // @ts-ignore
        window.addEventListener("load", () => {
            // @ts-ignore
            navigator.serviceWorker
                .register("/sw.js")
                .then((registration) => {
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;
                        if (installingWorker) {
                            installingWorker.onstatechange = () => {
                                if (installingWorker.state === "installed") {
                                    // @ts-ignore
                                    if (navigator.serviceWorker.controller) {
                                        // Новый контент доступен, показываем уведомление пользователю
                                        // @ts-ignore
                                        console.log(
                                            "New content is available; please refresh.",
                                        );
                                        // Здесь можно добавить логику для отображения уведомления пользователю
                                    } else {
                                        // Контент кэширован впервые
                                        // @ts-ignore
                                        console.log(
                                            "Content is cached for offline use.",
                                        );
                                    }
                                }
                            };
                        }
                    };
                })
                .catch((error) => {
                    // @ts-ignore
                    console.error(
                        "Error during service worker registration:",
                        error,
                    );
                });
        });
    }
}
