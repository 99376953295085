import welcome from "@/utils/welcome";
import { registerSW } from "../sw-registration";

Promise.all([import("@/Root"), import("@/App")]).then(
    ([{ default: render }, { default: App }]) => {
        render(App);
    },
);

// welcome message for users in the console
welcome();
registerSW();

// ts(1208)
export {};
